import axios from 'axios';
import config from "@/config";

function hasOneDigit(val) {
    return String(Math.abs(val)).charAt(0) == val;
}

/**
 * Función para cambiar el formato de la fecha
 * 
 * @returns {number} La fecha con el formato cambiado a "dia-mes-año"
 */
export const changeDateFormat = (dateWithoutFormat) => {
    if (dateWithoutFormat == "") return dateWithoutFormat;
    let date = new Date(Date.parse(dateWithoutFormat))
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    if (hasOneDigit(day) && hasOneDigit(month)) {
        return '0' + day + "/" + '0' +  + month + "/" +  year;
    } else if (hasOneDigit(day)) {
        return '0' + day + "/" + month + "/" + year;
    } else if (hasOneDigit(month)) {
        return day + "/" + '0' + month + "/" + year;
    } else {
        return day + "/" + month + "/" + year;
    }
}

export const decodePayload = (token) => {
    try {
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let payload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(payload)
    } catch (error) {
        return false
    }
}

export function checkUser() {
    let user = decodePayload(localStorage.token);
    if (user.type != 1) {
        localStorage.removeItem("token");
        window.location = "#/pages/login";
        return false;
    }
    return true;
}




/**
 * Función para conseguir los datos de los usuarios
 * 
 * @returns {Promise<object>} Devuelve una promesa que al resolverse nos da los datos de los usuarios
 */
export async function getUsersData() {
    let data;
    const token = localStorage.token;
    await axios
        .get(config.BACK_IP + "/user", { headers: { token: token, } }).then((result) => {
            data = result.data;
        })
        .catch(function (error) {
            if (error.response) {
                if (error.response.status == 401) {
                    localStorage.removeItem("token");
                    window.location = "#/pages/login";
                }
                if (error.response.status == 404) {
                    window.location = "#/pages/404";
                }
                if (error.response.status == 500) {
                    window.location = "#/500";
                }
            }
        });
    return data;
}

/**
 * Función para conseguir los datos de los usuarios
 * 
 * @returns {Promise<object>} Devuelve una promesa que al resolverse nos da los datos de los usuarios
 */
 export async function getUserDataPerID(id) {
    let data;
    const token = localStorage.token;
    await axios
        .get(config.BACK_IP + "/user/" + id, { headers: { token: token, }}).then((result) => {
            data = result.data;
        })
        .catch(function (error) {
            if (error.response) {
                if (error.response.status == 401) {
                    localStorage.removeItem("token");
                    window.location = "#/pages/login";
                }
                if (error.response.status == 404) {
                    window.location = "#/pages/404";
                }
                if (error.response.status == 500) {
                    window.location = "#/500";
                }
            }
        });
    return data;
}



/**
 * Función para conseguir los datos de las patologías
 * 
 * @returns {Promise<object>} Promesa que al resolverse da las patologias
 */
export async function getPathologiesData() {
    let data;
    const token = localStorage.token;
    await axios
        .get(config.BACK_IP + "/pathology", {
            headers: {
                token: token,
            },
        })
        .then((result) => {
            data = result.data;
        })
        .catch(function (error) {
            if (error.response) {
                if (error.response.status == 401) {
                    localStorage.removeItem("token");
                    window.location = "#/pages/login";
                }
                if (error.response.status == 404) {
                    window.location = "#/pages/404";
                }
                if (error.response.status == 500) {
                    window.location = "#/500";
                }
            }
        });
    return data;
}


/**
 * Función para conseguir los datos de las consejos
 * 
 * @returns {Promise<object>} Promesa que al resolverse da los consejos
 */
export async function getAdvicesData() {
    let data;
    const token = localStorage.token;
    await axios
        .get(config.BACK_IP + "/advice/all", {
            headers: {
                token: token,
            },
        })
        .then((result) => {
            data = result.data;
        })
        .catch(function (error) {
            if (error.response) {
                if (error.response.status == 401) {
                    localStorage.removeItem("token");
                    window.location = "#/pages/login";
                }
                if (error.response.status == 404) {
                    window.location = "#/pages/404";
                }
                if (error.response.status == 500) {
                    window.location = "#/500";
                }
            }
        });
    return data;
}

/**
 * Función para conseguir las patologías de un usuario
 * 
 * @returns {Promise<object>} Promesa que al resolverse devuelve las patologías
 */
export async function getUserPathologies(id) {
    let data;
    const token = localStorage.token;
    await axios
        .get(config.BACK_IP + "/pathology/" + id, {
            headers: {
                token: token,
            },
        })
        .then((result) => {
            data = result.data;
        })
        .catch(function (error) {
            if (error.response) {
                if (error.response.status == 401) {
                    localStorage.removeItem("token");
                    window.location = "#/pages/login";
                }
                if (error.response.status == 404) {
                    window.location = "#/pages/404";
                }
                if (error.response.status == 500) {
                    window.location = "#/500";
                }
            }
        });
    return data;
}


/**
 * Función para conseguir los datos de las alertas
 * 
 * @returns {Promise<object>} Promesa que al resolverse da las alertas
 */
export async function getAlertsData() {
    let data;
    const token = localStorage.token;
    await axios
        .get(config.BACK_IP + "/alerts/all", {
            headers: {
                token: token,
            },
        })
        .then((result) => {
            data = result.data;
        })
        .catch(function (error) {
            if (error.response) {
                if (error.response.status == 401) {
                    localStorage.removeItem("token");
                    window.location = "#/pages/login";
                }
                if (error.response.status == 404) {
                    window.location = "#/pages/404";
                }
                if (error.response.status == 500) {
                    window.location = "#/500";
                }
            }
        });
    return data;
}


/**
 * Función para conseguir los tipos de alerta
 * 
 * @returns {Promise<object>} Promesa que al resolverse da los tipos de alerta
 */

export async function getAlertTypeData() {
    let data;
    const token = localStorage.token;
    await axios
        .get(config.BACK_IP + "/alert_type", {
            headers: {
                token: token,
            },
        })
        .then((result) => {
            data = result.data;
        })
        .catch(function (error) {
            if (error.response) {
                if (error.response.status == 401) {
                    localStorage.removeItem("token");
                    window.location = "#/pages/login";
                }
                if (error.response.status == 404) {
                    window.location = "#/pages/404";
                }
                if (error.response.status == 500) {
                    window.location = "#/500";
                }
            }
        });
    return data;
}

/**
 * Función para conseguir las alertas de un usuario
 * 
 * @returns {Promise<object>} Promesa que al resolverse devuelve las alertas
 */
 export async function getUserAlerts(id) {
    let data;
    const token = localStorage.token;
    await axios
        .get(config.BACK_IP + "/alerts/" + id, {
            headers: {
                token: token,
            },
        })
        .then((result) => {
            data = result.data;
        })
        .catch(function (error) {
            if (error.response) {
                if (error.response.status == 401) {
                    localStorage.removeItem("token");
                    window.location = "#/pages/login";
                }
                if (error.response.status == 404) {
                    window.location = "#/pages/404";
                }
                if (error.response.status == 500) {
                    window.location = "#/500";
                }
            }
        });
    return data;
}



/**
 * Función para conseguir los datos de los retos
 * 
 * @returns {Promise<object>} Promesa que al resolverse da los retos
 */
export async function getChallengesData() {
    let data;
    const token = localStorage.token;
    await axios
        .get(config.BACK_IP + "/challenge", {
            headers: {
                token: token,
            },
        })
        .then((result) => {
            data = result.data;
        })
        .catch(function (error) {
            if (error.response) {
                if (error.response.status == 401) {
                    localStorage.removeItem("token");
                    window.location = "#/pages/login";
                }
                if (error.response.status == 404) {
                    window.location = "#/pages/404";
                }
                if (error.response.status == 500) {
                    window.location = "#/500";
                }
            }
        });
    return data;
}

/**
 * Función para conseguir los tipos de retos
 * 
 * @returns {Promise<object>} Promesa que al resolverse da los tipos de Retos
 */

export async function getChallengesDataTypes() {
    let data;
    const token = localStorage.token;
    await axios
        .get(config.BACK_IP + "/challenge_type", {
            headers: {
                token: token,
            },
        })
        .then((result) => {
            data = result.data;
        })
        .catch(function (error) {
            if (error.response) {
                if (error.response.status == 401) {
                    localStorage.removeItem("token");
                    window.location = "#/pages/login";
                }
                if (error.response.status == 404) {
                    window.location = "#/pages/404";
                }
                if (error.response.status == 500) {
                    window.location = "#/500";
                }
            }
        });
    return data;
}

/**
 * Función para conseguir los retos de un usuario
 * 
 * @returns {Promise<object>} Promesa que al resolverse devuelve los retos
 */
export async function getUserChallenges(id) {
    let data;
    const token = localStorage.token;
    await axios
        .get(config.BACK_IP + "/challenges/" + id, {
            headers: {
                token: token,
            },
        })
        .then((result) => {
            data = result.data;
        })
        .catch(function (error) {
            if (error.response) {
                if (error.response.status == 401) {
                    localStorage.removeItem("token");
                    window.location = "#/pages/login";
                }
                if (error.response.status == 404) {
                    window.location = "#/pages/404";
                }
                if (error.response.status == 500) {
                    window.location = "#/500";
                }
            }
        });
    return data;
}



/**
 * Función para conseguir los datos de los retos
 * 
 * @returns {Promise<object>} Promesa que al resolverse da los retos
 */
export async function getSessionsDataPerUserID(id) {
    let data;
    const token = localStorage.token;
    await axios
        .get(config.BACK_IP + "/session/" + id + "/list", {
            headers: {
                token: token,
            },
        })
        .then((result) => {
            data = result.data;
        })
        .catch(function (error) {
            if (error.response) {
                if (error.response.status == 401) {
                    localStorage.removeItem("token");
                    window.location = "#/pages/login";
                }
                if (error.response.status == 500) {
                    window.location = "#/500";
                }
            }
        });
    return data;
}

/**
 * Función para conseguir los datos de los retos
 * 
 * @returns {Promise<object>} Promesa que al resolverse devuelve los retos
 */
export async function getSessionDataPerSessionID(id_user, id_session) {
    let data;
    const token = localStorage.token;
    await axios.get(config.BACK_IP + "/session/past/" + id_user, { headers: { token: token }, params: { session_id: id_session } })
        .then((result) => {
            data = result.data;
        })
        .catch(function (error) {
            if (error.response) {
                if (error.response.status == 401) {
                    localStorage.removeItem("token");
                    window.location = "#/pages/login";
                }
                if (error.response.status == 500) {
                    window.location = "#/500";
                }
            }
        });
    return data;
}

/**
 * Función para conseguir los datos de los limites de cada usuario
 * 
 * @returns {Promise<object>} Devuelve una promesa que al resolverse nos da los datos de los limites de cada usuario
 */
 export async function getLimit() {
    let data;
    const token = localStorage.token;
    await axios
        .get(config.BACK_IP + "/limit", { headers: { token: token, }}).then((result) => {
            data = result.data;
        })
        .catch(function (error) {
            if (error.response) {
                if (error.response.status == 401) {
                    localStorage.removeItem("token");
                    window.location = "#/pages/login";
                }
                if (error.response.status == 404) {
                    window.location = "#/pages/404";
                }
                if (error.response.status == 500) {
                    window.location = "#/500";
                }
            }
        });
    return data;
}


/**
 * Función para conseguir los datos de los limites de un usuario pasado la id por url
 * 
 * @returns {Promise<object>} Devuelve una promesa que al resolverse nos da los datos de los limites de un usuario
 */
 export async function getUserLimit(id) {
    let data;
    const token = localStorage.token;
    await axios
        .get(config.BACK_IP + "/limit/"+id, { headers: { token: token, }}).then((result) => {
            data = result.data;
        })
        .catch(function (error) {
            if (error.response) {
                if (error.response.status == 401) {
                    localStorage.removeItem("token");
                    window.location = "#/pages/login";
                }
                if (error.response.status == 404) {
                    window.location = "#/pages/404";
                }
                if (error.response.status == 500) {
                    window.location = "#/500";
                }
            }
        });
    return data;
}